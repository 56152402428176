import { environment } from "src/environments/environment";
import { AddressData, AreaAmenityMapConfig, CommunityConfig, RanchSubdivisions } from "../types/community.types";

export const CommunityConfigData: CommunityConfig = {
  amenitySections: [
    {
      title: 'Community Parks',
      tagline: 'Multiple on-site green spaces provide a blank canvas for you to enjoy. Set up an impromptu picnic with friends under the lush vegetation. Let kids or pups run off energy in the fresh air.',
      icon: 'assets/icons/Amenities_Butterfly.svg',
      img: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-CommunityPark.png`,
      imgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-CommunityPark.png`
    },
    {
      title: 'Winding Trails',
      tagline: 'It\'s easy to get moving with a full system of community trails that interconnect. Ideal for walking, running or biking, these paths are a perfect way to stay active and explore the land.',
      icon: 'assets/icons/Amenities_Bike.svg',
      img: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-WindingTrails.png`,
      imgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-WindingTrails.png`
    },
    {
      title: 'Sports Fields',
      tagline: 'Whether used for scheduled leagues or pick-up games, these grass fields are sure to be a popular pastime. Just bring some basic equipment and you can play soccer, lacrosse, frisbee and more.',
      icon: 'assets/icons/Amenities_Soccer.svg',
      img: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-SportsField.png`,
      imgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-SportsField.png`
    },
    {
      title: 'Playgrounds',
      tagline: 'Built with our youngest residents in mind, kids will have their choice of nearby playgrounds to pick from. Each one will offer hours of fun with slides, swings and a place to see their pals.',
      icon: 'assets/icons/Amenities_Playground.svg',
      img: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-Playground.png`,
      imgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-Playground.png`
    },
    {
      title: 'Nature Preserve',
      tagline: 'Serving as the undisputed main attraction, our nature preserve is the inspiration for The Ranch as a whole. It\'s truly the crown jewel of the entire community, offering awe-inspiring views.',
      icon: 'assets/icons/Amenities_Trees.svg',
      img: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-NaturePreserve.png`,
      imgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Amenity+Page/Amenity-NaturePreserve.png`
    },
  ],
  siteplanData: {
    center: { lat: 38.546417206870785, lng: -121.21511199889939 },
    zoom: 16,
    regions: {
      [RanchSubdivisions.Paseo]: {
        id: RanchSubdivisions.Paseo,
        color: '#AD2129',
        info: 'Single Family Homes, up to 2,028 sq. ft.',
        points: [
          {"lat":38.55186533528778,"lng":-121.22399395331743,"id":0},
          {"lat":38.55077876699668,"lng":-121.2240100465715,"id":1},
          {"lat":38.55073681422378,"lng":-121.22362380847335,"id":2},
          {"lat":38.550489292365306,"lng":-121.22363453730941,"id":3},
          {"lat":38.55002780865895,"lng":-121.220673378557,"id":4},
          {"lat":38.550396995861014,"lng":-121.22029786929491,"id":5},
          {"lat":38.55069486142638,"lng":-121.21978824958208,"id":6},
          {"lat":38.551093412013294,"lng":-121.22010475024584,"id":7},
          {"lat":38.55184016400735,"lng":-121.22013693675399,"id":8},
          {"lat":38.55187792092468,"lng":-121.22402613982561,"id":9}
        ]
      },
      [RanchSubdivisions.Sagebrush]: {
        id: RanchSubdivisions.Sagebrush,
        color: '#273A90',
        info: 'Single Family Homes, up to 2,014 sq. ft.',
        points: [
          {"lat":38.55185730538809,"lng":-121.22005999088289,"id":0},
          {"lat":38.55120704441157,"lng":-121.2200438976288,"id":1},
          {"lat":38.5507413700339,"lng":-121.21972739696504,"id":2},
          {"lat":38.54999834308591,"lng":-121.22054329141976,"id":3},
          {"lat":38.54920122756275,"lng":-121.2188374064863,"id":4},
          {"lat":38.549486512028516,"lng":-121.2184618972242,"id":5},
          {"lat":38.549650130549615,"lng":-121.21851017698647,"id":6},
          {"lat":38.55037592103889,"lng":-121.21753385290505,"id":7},
          {"lat":38.55012839793802,"lng":-121.21730854734781,"id":8},
          {"lat":38.55078138904418,"lng":-121.21654784772547,"id":9},
          {"lat":38.551469411002245,"lng":-121.21615088079126,"id":10},
          {"lat":38.55189732377725,"lng":-121.21610796544702,"id":11},
          {"lat":38.55187215250805,"lng":-121.22009372804315,"id":12}
        ]
      },
      [RanchSubdivisions.Reserve]: {
        id: RanchSubdivisions.Reserve,
        color: '#B23B82',
        info: 'Age-Restricted, Single Family Homes.',
        points: [
          {"lat":38.54582413914079,"lng":-121.21367658488452,"id":0},
          {"lat":38.54503956417803,"lng":-121.21236230246726,"id":1},
          {"lat":38.544947949021804,"lng":-121.21174346655609,"id":2},
          {"lat":38.54522066356213,"lng":-121.21138405054809,"id":3},
          {"lat":38.545619244491476,"lng":-121.21178638190034,"id":4},
          {"lat":38.54646704107034,"lng":-121.21164242271335,"id":5},
          {"lat":38.54663905749788,"lng":-121.21206084731968,"id":6},
          {"lat":38.546894983616305,"lng":-121.2118677282706,"id":7},
          {"lat":38.54689917911884,"lng":-121.21141175273807,"id":8},
          {"lat":38.54705860803436,"lng":-121.21055344585331,"id":9},
          {"lat":38.5470963674626,"lng":-121.20950201991947,"id":10},
          {"lat":38.546647448532575,"lng":-121.20882073882969,"id":11},
          {"lat":38.546832051048675,"lng":-121.20843986514957,"id":12},
          {"lat":38.5475075243976,"lng":-121.20837549213321,"id":13},
          {"lat":38.54759962794866,"lng":-121.20571658480912,"id":14},
          {"lat":38.54072487170379,"lng":-121.20547895785421,"id":15},
          {"lat":38.540699696523546,"lng":-121.2098670518026,"id":16},
          {"lat":38.541350052519626,"lng":-121.20996897574517,"id":17},
          {"lat":38.54133746504003,"lng":-121.21295695658776,"id":18},
          {"lat":38.54065773787026,"lng":-121.2131447112188,"id":19},
          {"lat":38.54053694270437,"lng":-121.2166866939515,"id":20},
          {"lat":38.541229258516445,"lng":-121.21692809276286,"id":21},
          {"lat":38.541867022337215,"lng":-121.21646675281228,"id":22},
          {"lat":38.54207261604774,"lng":-121.21575328521433,"id":23},
          {"lat":38.54208939918183,"lng":-121.21462139301003,"id":24},
          {"lat":38.54238893813129,"lng":-121.2142517929897,"id":25},
          {"lat":38.542451874587165,"lng":-121.21298042591663,"id":26},
          {"lat":38.54253998553284,"lng":-121.21225622948262,"id":27},
          {"lat":38.54366863524208,"lng":-121.21226159390064,"id":28},
          {"lat":38.5439267025485,"lng":-121.21236527804288,"id":29},
          {"lat":38.54414068169839,"lng":-121.21286953333768,"id":30},
          {"lat":38.54408613805398,"lng":-121.21355081442744,"id":31},
          {"lat":38.54375467963296,"lng":-121.2137922132388,"id":32},
          {"lat":38.544493116557454,"lng":-121.21493483427912,"id":33},
          {"lat":38.544883998623526,"lng":-121.21491002384576,"id":34},
          {"lat":38.545760877857184,"lng":-121.2137995893136,"id":35}
        ]
      },
      [RanchSubdivisions.Canyon]: {
        id: RanchSubdivisions.Canyon,
        color: '#7E4821',
        info: 'Single Family Homes, up to 2,014 sq. ft.',
        points: [
          {"lat":38.55017739787798,"lng":-121.21723642107101,"id":0},
          {"lat":38.54960683329692,"lng":-121.21621718164535,"id":1},
          {"lat":38.551399304645315,"lng":-121.21412828564645,"id":2},
          {"lat":38.551571309273804,"lng":-121.21374204754831,"id":3},
          {"lat":38.551571309273804,"lng":-121.21242776513103,"id":4},
          {"lat":38.55145384274281,"lng":-121.21207371354106,"id":5},
          {"lat":38.551919512505954,"lng":-121.21151581406595,"id":6},
          {"lat":38.55187336518658,"lng":-121.2160648405552,"id":7},
          {"lat":38.55131120455428,"lng":-121.21615067124368,"id":8},
          {"lat":38.55079305715439,"lng":-121.21650686021897,"id":9},
          {"lat":38.55016376311545,"lng":-121.21727397199723,"id":10}
        ]
      },
      [RanchSubdivisions.Springs]: {
        id: RanchSubdivisions.Springs,
        color: '#677B5B',
        info: 'Single Family Homes, up to 2,150 sq. ft.',
        points: [
          [
            {"lat":38.54820420422259,"lng":-121.21783503572499,"id":0},
            {"lat":38.547188903893534,"lng":-121.21948191206013,"id":1},
            {"lat":38.54633301975323,"lng":-121.21823200265919,"id":2},
            {"lat":38.54575445964149,"lng":-121.2156013004393,"id":3},
            {"lat":38.546245339564194,"lng":-121.21458206101364,"id":4},
            {"lat":38.548219412641274,"lng":-121.2177512586028,"id":5}
          ], [
            {"lat":38.54852911333917,"lng":-121.22305572498591,"id":0},
            {"lat":38.54855009037278,"lng":-121.22236371506007,"id":1},
            {"lat":38.548944457465204,"lng":-121.22159660328181,"id":2},
            {"lat":38.54885635436621,"lng":-121.22133374679835,"id":3},
            {"lat":38.54877664194554,"lng":-121.22124255169184,"id":4},
            {"lat":38.5496870364386,"lng":-121.22066319454463,"id":5},
            {"lat":38.549338822394674,"lng":-121.219649319537,"id":6},
            {"lat":38.54883957281132,"lng":-121.21872127521785,"id":7},
            {"lat":38.54862560764299,"lng":-121.21887147892268,"id":8},
            {"lat":38.54848296384373,"lng":-121.22014821041375,"id":9},
            {"lat":38.54844940055574,"lng":-121.22058272827418,"id":10},
            {"lat":38.54813474396878,"lng":-121.2210547970608,"id":11},
            {"lat":38.5479962946343,"lng":-121.22197747696192,"id":12},
            {"lat":38.54794594935566,"lng":-121.22324884403498,"id":13},
            {"lat":38.54854589496653,"lng":-121.22306645382197,"id":14}
          ]
        ]
      },
      [RanchSubdivisions.Montrose]: {
        id: RanchSubdivisions.Montrose,
        color: '#663192',
        info: 'Single Family Homes, up to 2,628 sq. ft.',
        points: [
          {"lat":38.54771870684887,"lng":-121.21644768398257,"id":0},
          {"lat":38.549212273179506,"lng":-121.21878120582552,"id":1},
          {"lat":38.54950175295215,"lng":-121.21842715423556,"id":2},
          {"lat":38.54961502732857,"lng":-121.21849152725193,"id":3},
          {"lat":38.55035746827355,"lng":-121.2175487307832,"id":4},
          {"lat":38.550118335738844,"lng":-121.21730196755381,"id":5},
          {"lat":38.54960231019102,"lng":-121.2163471011445,"id":6},
          {"lat":38.54875065017086,"lng":-121.21520448010418,"id":7},
          {"lat":38.54772490169957,"lng":-121.21637463569643,"id":8}
        ]
      },

    },
    pdf: `${environment.S3_URL}pdfs/TheRanch_8.5x11-Sitemap.pdf`,
    overlayImage: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Siteplan/32535_KHOV_Ranch_SiteMap-NoKey_LT_i1.png`,
    overlayPosition: {
      bl: { lat: 38.540091, lng: -121.224607 },
      tr: { lat: 38.552247, lng: -121.205302 },
    }
  },
  areaAmenities: [
    {
      id: 0,
      color: '#9F9C99',
      iconCategory: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Icons/Schools.svg`,
      iconPin: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Schools_Pin.svg`,
      label: 'Schools',
      name: 'Schools',
      items: [
        {
          "id": 43,
          "name": "White Rock Elementary School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10487+White+Rock+Rd.,+Rancho+Cordova,+CA/38.58705738,-121.2999155",
            "entityId": 43,
            "country": "USA",
            "latitude": 38.58705738,
            "longitude": -121.2999155,
            "address1": "10487 White Rock Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 44,
          "name": "Saint John Vianney School - Rancho Cordova",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10499+Coloma+Rd.,+Rancho+Cordova,+CA/38.60319392,-121.300947",
            "entityId": 44,
            "country": "USA",
            "latitude": 38.60319392,
            "longitude": -121.300947,
            "address1": "10499 Coloma Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 45,
          "name": "Cosumnes River Elementary School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/13580+Jackson+Rd.,+Sloughhouse,+CA/38.49311747,-121.1573128",
            "entityId": 45,
            "country": "USA",
            "latitude": 38.49311747,
            "longitude": -121.1573128,
            "address1": "13580 Jackson Rd.",
            "city": "Sloughhouse",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 46,
          "name": "Rancho Cordova Elementary School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2562+Chassella+Way.,+Mather,+CA/38.6000575,-121.295677",
            "entityId": 46,
            "country": "USA",
            "latitude": 38.6000575,
            "longitude": -121.295677,
            "address1": "2562 Chassella Way.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 47,
          "name": "Capitol Academy",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3063+Gold+Canal+Dr.,+Rancho+Cordova,+CA/38.59244111,-121.2681182",
            "entityId": 47,
            "country": "USA",
            "latitude": 38.59244111,
            "longitude": -121.2681182,
            "address1": "3063 Gold Canal Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 48,
          "name": "Robert J. McGarvey Elementary School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4350+Sophistry+Dr.,+Rancho+Cordova,+CA/38.54481288,-121.2331274",
            "entityId": 48,
            "country": "USA",
            "latitude": 38.54481288,
            "longitude": -121.2331274,
            "address1": "4350 Sophistry Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 49,
          "name": "Mather Heights Elementary School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4370+School+Rd.,+Mather,+CA/38.54431395,-121.2778489",
            "entityId": 49,
            "country": "USA",
            "latitude": 38.54431395,
            "longitude": -121.2778489,
            "address1": "4370 School Rd.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 50,
          "name": "Folsom Cordova Community Charter School",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4420+Monhegan+Way.,+Mather,+CA/38.54465177,-121.2890696",
            "entityId": 50,
            "country": "USA",
            "latitude": 38.54465177,
            "longitude": -121.2890696,
            "address1": "4420 Monhegan Way.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        }
      ]
    },
    {
      id: 2,
      color: '#BE9760',
      iconCategory: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Icons/Shopping.svg`,
      iconPin: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Shopping_Pin.svg`,
      label: 'Shopping',
      name: 'Shopping',
      items: [
        {
          "id": 51,
          "name": "Mills Center",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10355+Folsom+Blvd.,+Rancho+Cordova,+CA/38.58865188,-121.3052309",
            "entityId": 51,
            "country": "USA",
            "latitude": 38.58865188,
            "longitude": -121.3052309,
            "address1": "10355 Folsom Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 52,
          "name": "Walmart Supercenter",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10655+Folsom+Blvd.,+Rancho+Cordova,+CA/38.59620998,-121.2917657",
            "entityId": 52,
            "country": "USA",
            "latitude": 38.59620998,
            "longitude": -121.2917657,
            "address1": "10655 Folsom Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 53,
          "name": "Dollar Tree",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10701+Folsom+Blvd.,+Rancho+Cordova,+CA/38.59695858,-121.2881282",
            "entityId": 53,
            "country": "USA",
            "latitude": 38.59695858,
            "longitude": -121.2881282,
            "address1": "10701 Folsom Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 54,
          "name": "Big 5 Sporting Goods - Rancho Cordova",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10755+Folsom+Blvd.,+Rancho+Cordova,+CA/38.59821221,-121.286207",
            "entityId": 54,
            "country": "USA",
            "latitude": 38.59821221,
            "longitude": -121.286207,
            "address1": "10755 Folsom Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 55,
          "name": "PetSmart",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10830+Olson+Dr.,+Rancho+Cordova,+CA/38.59359345,-121.2846419",
            "entityId": 55,
            "country": "USA",
            "latitude": 38.59359345,
            "longitude": -121.2846419,
            "address1": "10830 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 56,
          "name": "Ross Dress for Less",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10835+Olson+Dr.,+Rancho+Cordova,+CA/38.59500321,-121.2862151",
            "entityId": 56,
            "country": "USA",
            "latitude": 38.59500321,
            "longitude": -121.2862151,
            "address1": "10835 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 57,
          "name": "Marshalls",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10837+Olson+Dr.,+Rancho+Cordova,+CA/38.5952495,-121.2861722",
            "entityId": 57,
            "country": "USA",
            "latitude": 38.5952495,
            "longitude": -121.2861722,
            "address1": "10837 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 58,
          "name": "Harbor Freight Tools",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10873+Folsom+Blvd.,+Rancho+Cordova,+CA/38.60058074,-121.2817132",
            "entityId": 58,
            "country": "USA",
            "latitude": 38.60058074,
            "longitude": -121.2817132,
            "address1": "10873 Folsom Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 59,
          "name": "Target",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10881+Olson+Dr.,+Rancho+Cordova,+CA/38.59683229,-121.2858385",
            "entityId": 59,
            "country": "USA",
            "latitude": 38.59683229,
            "longitude": -121.2858385,
            "address1": "10881 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 60,
          "name": "KP International Market",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10971+Olson+Dr.,+Rancho+Cordova,+CA/38.59687525,-121.2836259",
            "entityId": 60,
            "country": "USA",
            "latitude": 38.59687525,
            "longitude": -121.2836259,
            "address1": "10971 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 61,
          "name": "Coloma Grocery",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2225+McGregor+Dr.,+Rancho+Cordova,+CA/38.61109735,-121.290128",
            "entityId": 61,
            "country": "USA",
            "latitude": 38.61109735,
            "longitude": -121.290128,
            "address1": "2225 McGregor Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 62,
          "name": "Staples",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2690+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.60348192,-121.2690494",
            "entityId": 62,
            "country": "USA",
            "latitude": 38.60348192,
            "longitude": -121.2690494,
            "address1": "2690 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 63,
          "name": "The Home Depot",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2756+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.60081126,-121.2681588",
            "entityId": 63,
            "country": "USA",
            "latitude": 38.60081126,
            "longitude": -121.2681588,
            "address1": "2756 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 64,
          "name": "Grocery Outlet",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2801+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.5962747,-121.2872435",
            "entityId": 64,
            "country": "USA",
            "latitude": 38.5962747,
            "longitude": -121.2872435,
            "address1": "2801 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 65,
          "name": "Lowe's Home Improvement",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3251+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58387997,-121.2825412",
            "entityId": 65,
            "country": "USA",
            "latitude": 38.58387997,
            "longitude": -121.2825412,
            "address1": "3251 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 66,
          "name": "Sonny's Market",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3291+Mather+Field+Rd.,+Rancho+Cordova,+CA/38.57555145,-121.3039311",
            "entityId": 66,
            "country": "USA",
            "latitude": 38.57555145,
            "longitude": -121.3039311,
            "address1": "3291 Mather Field Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 67,
          "name": "Anatolia Shopping Center",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4030+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.55765664,-121.2447939",
            "entityId": 67,
            "country": "USA",
            "latitude": 38.55765664,
            "longitude": -121.2447939,
            "address1": "4030 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        }
      ]
    },
    {
      id: 3,
      color: '#3E7E98',
      iconCategory: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Icons/Dining.svg`,
      iconPin: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Dining.svg`,
      label: 'Dining',
      name: 'Dining',
      items: [
        {
          "id": 24,
          "name": "A&W Restaurant",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10399+Folsom+Blvd,+Rancho+Cordova,+CA/38.58939894,-121.3035489",
            "entityId": 24,
            "country": "USA",
            "latitude": 38.58939894,
            "longitude": -121.3035489,
            "address1": "10399 Folsom Blvd",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 25,
          "name": "Zak's Bar & Grill",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10750+Olson+Dr.,+Rancho+Cordova,+CA/38.59306625,-121.287",
            "entityId": 25,
            "country": "USA",
            "latitude": 38.59306625,
            "longitude": -121.287,
            "address1": "10750 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 26,
          "name": "Thai Mango Restaurant",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10821+Olson+Dr.,+Rancho+Cordova,+CA/38.59465013,-121.2858606",
            "entityId": 26,
            "country": "USA",
            "latitude": 38.59465013,
            "longitude": -121.2858606,
            "address1": "10821 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 27,
          "name": "El Puerto Restaurant",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10851+Folsom+Blvd,+Rancho+Cordova,+CA/38.60058997,-121.2824359",
            "entityId": 27,
            "country": "USA",
            "latitude": 38.60058997,
            "longitude": -121.2824359,
            "address1": "10851 Folsom Blvd",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 28,
          "name": "Golden Bean Cafe",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10860+Gold+Center+Dr.,+Rancho+Cordova,+CA/38.59095987,-121.2791936",
            "entityId": 28,
            "country": "USA",
            "latitude": 38.59095987,
            "longitude": -121.2791936,
            "address1": "10860 Gold Center Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 29,
          "name": "New York Bagels & Sandwiches",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10929+Olson+Dr.,+Rancho+Cordova,+CA/38.59554315,-121.2834949",
            "entityId": 29,
            "country": "USA",
            "latitude": 38.59554315,
            "longitude": -121.2834949,
            "address1": "10929 Olson Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 30,
          "name": "Formaggio Taverna and Patio",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/11211+Point+E+Dr.,+Rancho+Cordova,+CA/38.60723599,-121.2693095",
            "entityId": 30,
            "country": "USA",
            "latitude": 38.60723599,
            "longitude": -121.2693095,
            "address1": "11211 Point E Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 31,
          "name": "The LODGE",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2801+Prospect+Park+Dr.,+Rancho+Cordova,+CA/38.59209637,-121.2843198",
            "entityId": 31,
            "country": "USA",
            "latitude": 38.59209637,
            "longitude": -121.2843198,
            "address1": "2801 Prospect Park Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 32,
          "name": "Teriyaki Grill",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2808+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.59549041,-121.2890114",
            "entityId": 32,
            "country": "USA",
            "latitude": 38.59549041,
            "longitude": -121.2890114,
            "address1": "2808 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 33,
          "name": "Chipotle Mexican Grill",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2878+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.59460314,-121.2884909",
            "entityId": 33,
            "country": "USA",
            "latitude": 38.59460314,
            "longitude": -121.2884909,
            "address1": "2878 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 34,
          "name": "Lots of Luck Chinese Food Cafe",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3084+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.5914946,-121.2664606",
            "entityId": 34,
            "country": "USA",
            "latitude": 38.5914946,
            "longitude": -121.2664606,
            "address1": "3084 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 35,
          "name": "Famous Burgers & Brew",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3101+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58798291,-121.2854853",
            "entityId": 35,
            "country": "USA",
            "latitude": 38.58798291,
            "longitude": -121.2854853,
            "address1": "3101 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 36,
          "name": "Noodles and Company",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3151+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58536109,-121.2855088",
            "entityId": 36,
            "country": "USA",
            "latitude": 38.58536109,
            "longitude": -121.2855088,
            "address1": "3151 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 37,
          "name": "The Habit Burger Grill",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3161+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.5858132,-121.2845918",
            "entityId": 37,
            "country": "USA",
            "latitude": 38.5858132,
            "longitude": -121.2845918,
            "address1": "3161 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 38,
          "name": "Beijing Cafe",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3187+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58464311,-121.2834696",
            "entityId": 38,
            "country": "USA",
            "latitude": 38.58464311,
            "longitude": -121.2834696,
            "address1": "3187 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 39,
          "name": "Starbucks",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3195+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58454996,-121.2844104",
            "entityId": 39,
            "country": "USA",
            "latitude": 38.58454996,
            "longitude": -121.2844104,
            "address1": "3195 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 40,
          "name": "Chili's Grill & Bar",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3199+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58437123,-121.2849438",
            "entityId": 40,
            "country": "USA",
            "latitude": 38.58437123,
            "longitude": -121.2849438,
            "address1": "3199 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 41,
          "name": "Franco's Cafe",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3320+Data+Dr.,+Rancho+Cordova,+CA/38.58542912,-121.2904743",
            "entityId": 41,
            "country": "USA",
            "latitude": 38.58542912,
            "longitude": -121.2904743,
            "address1": "3320 Data Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 42,
          "name": "Burgerim Gourmet Burgers",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4040+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.55713795,-121.2435411",
            "entityId": 42,
            "country": "USA",
            "latitude": 38.55713795,
            "longitude": -121.2435411,
            "address1": "4040 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        }
      ]
    },
    {
      id: 4,
      color: '#5B5B4B',
      iconCategory: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Icons/Entertainment.svg`,
      iconPin: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Entertainment_Pin.svg`,
      label: 'Entertainment',
      name: 'Entertainment',
      items: [
        {
          "id": 11,
          "name": "Distinct Physique Fitness",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10398+Rockingham+Dr.,+Sacramento,+CA/38.57384909,-121.3041059",
            "entityId": 11,
            "country": "USA",
            "latitude": 38.57384909,
            "longitude": -121.3041059,
            "address1": "10398 Rockingham Dr.",
            "city": "Sacramento",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 12,
          "name": "White Rock Neighborhood Splash Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10488+White+Rock+Rd.,+Rancho+Cordova,+CA/38.58532466,-121.3004416",
            "entityId": 12,
            "country": "USA",
            "latitude": 38.58532466,
            "longitude": -121.3004416,
            "address1": "10488 White Rock Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 13,
          "name": "Dave Roberts Community Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10805+Mapola+Way,+Rancho+Cordova,+CA/38.61203577,-121.2808469",
            "entityId": 13,
            "country": "USA",
            "latitude": 38.61203577,
            "longitude": -121.2808469,
            "address1": "10805 Mapola Way",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 14,
          "name": "Waterbrook Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10849+Waterbrook+Way,+Gold+River,+CA/38.57552847,-121.2826086",
            "entityId": 14,
            "country": "USA",
            "latitude": 38.57552847,
            "longitude": -121.2826086,
            "address1": "10849 Waterbrook Way",
            "city": "Gold River",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 15,
          "name": "Cobblestone Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10900+Barden+Dr.,+Rancho+Cordova,+CA/38.58162964,-121.2798489",
            "entityId": 15,
            "country": "USA",
            "latitude": 38.58162964,
            "longitude": -121.2798489,
            "address1": "10900 Barden Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 16,
          "name": "Heron Landing Community Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/11750+Justinian+Dr.,+Rancho+Cordova,+CA/38.53843615,-121.2407249",
            "entityId": 16,
            "country": "USA",
            "latitude": 38.53843615,
            "longitude": -121.2407249,
            "address1": "11750 Justinian Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 17,
          "name": "Eagle's Nest Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/11810+Chrysanthy+Blvd.,+Rancho+Cordova,+CA/38.54802175,-121.2379409",
            "entityId": 17,
            "country": "USA",
            "latitude": 38.54802175,
            "longitude": -121.2379409,
            "address1": "11810 Chrysanthy Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 18,
          "name": "Argonaut Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/11825+Herodian+Dr.,+Rancho+Cordova,+CA/38.55718914,-121.2386571",
            "entityId": 18,
            "country": "USA",
            "latitude": 38.55718914,
            "longitude": -121.2386571,
            "address1": "11825 Herodian Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 19,
          "name": "Parkwest Casino Cordova",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2801+Prospect+Park+Dr.,+Rancho+Cordova,+CA/38.59209637,-121.2843412",
            "entityId": 19,
            "country": "USA",
            "latitude": 38.59209637,
            "longitude": -121.2843412,
            "address1": "2801 Prospect Park Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 20,
          "name": "Hillside Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3566+Rockdale+Dr.,+Rancho+Cordova,+CA/38.56969302,-121.200259",
            "entityId": 20,
            "country": "USA",
            "latitude": 38.56969302,
            "longitude": -121.200259,
            "address1": "3566 Rockdale Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 21,
          "name": "Mather Sports Center",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3755+Schriever+Ave.,+Mather,+CA/38.57144912,-121.3016943",
            "entityId": 21,
            "country": "USA",
            "latitude": 38.57144912,
            "longitude": -121.3016943,
            "address1": "3755 Schriever Ave.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 22,
          "name": "Mather Golf Course",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4103+Zinfandel+Dr.,+Mather,+CA/38.55447238,-121.2594058",
            "entityId": 22,
            "country": "USA",
            "latitude": 38.55447238,
            "longitude": -121.2594058,
            "address1": "4103 Zinfandel Dr.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 23,
          "name": "Mather Regional Park",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/4111+Old+Eagle's+Nest+Rd.,+Rancho+Cordova,+CA/38.5571551,-121.2595362",
            "entityId": 23,
            "country": "USA",
            "latitude": 38.5571551,
            "longitude": -121.2595362,
            "address1": "4111 Old Eagle's Nest Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        }
      ]
    },
    {
      id: 5,
      color: '#BD6228',
      iconCategory: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Icons/Medical.svg`,
      iconPin: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Medical_Pin.svg`,
      label: 'Medical',
      name: 'Medical',
      items: [
        {
          "id": 0,
          "name": "Sacramento Family Medical Centers",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10390+Coloma+Rd.,+Rancho+Cordova,+CA/38.5964166,-121.3043373",
            "entityId": 0,
            "country": "USA",
            "latitude": 38.5964166,
            "longitude": -121.3043373,
            "address1": "10390 Coloma Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 1,
          "name": "Action Urgent Care",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10635+Folsom+Blvd,+Rancho+Cordova,+CA+95670/38.59566275,-121.293342",
            "entityId": 1,
            "country": "USA",
            "latitude": 38.59566275,
            "longitude": -121.293342,
            "address1": "10635 Folsom Blvd",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 2,
          "name": "Kaiser Permanente Rancho Cordova Medical Offices",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/10725+International+Dr.,+Rancho+Cordova,+CA/38.57831688,-121.2907091",
            "entityId": 2,
            "country": "USA",
            "latitude": 38.57831688,
            "longitude": -121.2907091,
            "address1": "10725 International Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 3,
          "name": "Gold River Pediatric Group",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/1995+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.61238266,-121.2712858",
            "entityId": 3,
            "country": "USA",
            "latitude": 38.61238266,
            "longitude": -121.2712858,
            "address1": "1995 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 4,
          "name": "Zinfandel Dental Practice",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2001+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.61197558,-121.2715115",
            "entityId": 4,
            "country": "USA",
            "latitude": 38.61197558,
            "longitude": -121.2715115,
            "address1": "2001 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 5,
          "name": "Allmed Medical Center",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2485+Sunrise+Blvd.,+Rancho+Cordova,+CA/38.59372537,-121.2776887",
            "entityId": 5,
            "country": "USA",
            "latitude": 38.59372537,
            "longitude": -121.2776887,
            "address1": "2485 Sunrise Blvd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 6,
          "name": "Live Well Medical Center",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/2828+Mills+Park+Dr.,+Rancho+Cordova,+CA/38.59295498,-121.2979716",
            "entityId": 6,
            "country": "USA",
            "latitude": 38.59295498,
            "longitude": -121.2979716,
            "address1": "2828 Mills Park Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 7,
          "name": "Rancho Pet Hospital",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3101+Zinfandel+Dr.,+Rancho+Cordova,+CA/38.58791582,-121.2855497",
            "entityId": 7,
            "country": "USA",
            "latitude": 38.58791582,
            "longitude": -121.2855497,
            "address1": "3101 Zinfandel Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 8,
          "name": "Mather Field Dental",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3342+Mather+Field+Rd.,+Rancho+Cordova,+CA/38.57421644,-121.3034802",
            "entityId": 8,
            "country": "USA",
            "latitude": 38.57421644,
            "longitude": -121.3034802,
            "address1": "3342 Mather Field Rd.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 9,
          "name": "Dignity Health",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3400+Data+Dr.,+Rancho+Cordova,+CA/38.58520496,-121.2949845",
            "entityId": 9,
            "country": "USA",
            "latitude": 38.58520496,
            "longitude": -121.2949845,
            "address1": "3400 Data Dr.",
            "city": "Rancho Cordova",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        },
        {
          "id": 10,
          "name": "Sutter Health",
          "description": "",
          "address": {
            "address2": "",
            "directions": "https://google.com/maps/place/3707+Schriever+Ave.,+Mather,+CA/38.57232236,-121.3041427",
            "entityId": 10,
            "country": "USA",
            "latitude": 38.57232236,
            "longitude": -121.3041427,
            "address1": "3707 Schriever Ave.",
            "city": "Mather",
            "stateLabel": "",
            "stateName": "",
            "zip": "CA"
          },
          "phone": "",
          "phoneExt": "",
          "status": "",
          "websiteUrl": ""
        }
      ]
    }
  ],
};

const MapStyling = [
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#dd9b9b"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "saturation": "-8"
      },
      {
        "color": "#cec7aa"
      },
      {
        "lightness": "-23"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ff0000"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffba00"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f2f2f2"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#46bcec"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#c6d7d4"
      }
    ]
  }
];

export const RanchLocation = {
  lat: 38.5501295, lng: -121.2237787
};

export const CommunityAreaMapConfig: AreaAmenityMapConfig = {
  options: {
    zoomControl: true,
    disableDefaultUI: true,
    minZoom: 8,
    styles: [
      ...MapStyling as Array<google.maps.MapTypeStyle>,
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "off" }
        ]
      },
    ],
  },
  center: { ...RanchLocation },
  zoom: 12,
  height: '70vh',
  width: '100%'
};

export const RanchAddress: AddressData & { telephone: string } = {
  address1: "4221 Rancho Cordova Pkwy",
  address2: "",
  city: "Rancho Cordova",
  zip: "95742",
  latitude: RanchLocation.lat,
  longitude: RanchLocation.lng,
  directions: "https://www.google.com/maps/place/4221+Rancho+Cordova+Pkwy,+Rancho+Cordova,+CA+95742",
  country: "USA",
  stateName: "California",
  stateLabel: "CA",
  entityId: 0,
  telephone: "888.802.1235"
}
