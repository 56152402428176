import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BDXResponsePayload } from '../types/home.type';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  constructor(private http: HttpClient) {}
  payload$: BehaviorSubject<BDXResponsePayload> = new BehaviorSubject(null);

  getGalleryData(): Observable<any> {
    return this.http.get<BDXResponsePayload>(`${environment.BDX_URL}`).pipe(
      map(data => ({
        communityImages: [], // TODO: ???
        homeDesignImages: data.Subdivision.map(sub =>
          sub.Plan.map(plan => ({
            src: plan.PlanImages.ElevationImage?.[0],
            caption: plan.PlanName
          }))
        ).reduce((a, it) => [...a, ...it], [])
      }))
    );
  }

  setPayload(payload: BDXResponsePayload): void {
    this.payload$.next(payload);
  }

  clearPayload(): void {
    this.payload$.next(null);
  }
}
