<div class="tab-nav-container">
  <div class="tab-nav-background {{ tabs.length > 2 ? 'more-than-two' : '' }}">
    <div class="tab-nav-bar {{ tabs.length > 2 ? 'more-than-two' : '' }}">
      <div
        *ngFor="let tab of tabs; index as i"
        class="tab-nav-item {{ tabs.length > 2 ? 'more-than-two' : '' }}"
        [ngClass]="{ 'tab-nav-item-active': index === i }"
        (click)="setTab(i)"
      >
        {{ tab }}
      </div>
    </div>
    <div class="tab-nav-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
