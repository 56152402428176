export interface Home {
  BasePrice: number;
  BaseSqft: number;
  Baths: number;
  Bedrooms: number;
  Description: string;
  Garage: number;
  HalfBaths: number;
  PlanImages: {
    ElevationImage: string[];
    FloorPlanImage: string[];
    InteriorImage: string[];
    PlanViewer: string;
    VirtualTour: string;
  };
  PlanInteractiveMedia: { EmbedCode: string; WebsiteURL: string };
  PlanName: string;
  PlanNotAvailable: 0;
  PlanNumber: string;
  PlanTypeName: string;
  Stories: 2;
  SubdivisionData: {
    SubVideoFile: string;
    SubWebsite: string;
    SubdivisionName: string;
    SubdivisionNumber: number;
  };
  Spec: {
    SpecAddress: {
      SpecCity: string;
      SpecCountry: string;
      SpecLot: number;
      SpecState: string;
      SpecStreet1: string;
      SpecZIP: number;
    };
    SpecBaths: number;
    SpecBedrooms: number;
    SpecDescription: string;
    SpecGarage: number;
    SpecHalfBaths: number;
    SpecImages: {
      SpecElevationImage: string[];
      SpecFloorPlanImage: string[];
      SpecVirtualTour: string;
    };
    SpecInteractiveMedia: {
      EmbedCode: string;
      WebsiteURL: string;
    };
    SpecMoveInDate: {
      [x: string]: string;
    };
    SpecNumber: number;
    SpecPrice: number;
    SpecSqft: number;
    SpecStories: number;
  };
}

export interface BDXResponsePayload {
  BuilderNumber:     string;
  BrandName:         string;
  BrandLogo_Med:     string;
  BrandLogo_Sm:      string;
  ReportingName:     string;
  DefaultLeadsEmail: string;
  BuilderWebsite:    string;
  Subdivision:       Subdivision[];
}

export interface Subdivision {
  SubdivisionNumber:   number;
  SubdivisionName:     string;
  MarketingChannel:    string;
  SubLeadsEmail:       string;
  BuildOnYourLot:      number;
  SalesOffice:         SalesOffice;
  SubAddress:          SubAddress;
  SubAmenity:          number[];
  SubDescription:      string;
  SubImage:            string[];
  SubVideoFile:        string;
  SubInteractiveMedia: InteractiveMedia;
  SubWebsite:          string;
  Promotion:           Promotion[];
  Plan:                Plan[];
}

export interface SubdivisionMedia {
  SubVideoFile: string,
  SubWebsite: string,
  SubdivisionName: string,
  SubdivisionNumber: number
}

export interface Plan {
  PlanNumber:           number | string;
  PlanName:             string;
  PlanNotAvailable:     number;
  PlanTypeName:         PlanTypeName;
  BasePrice:            number;
  BaseSqft:             number;
  Stories:              number;
  Baths:                number;
  HalfBaths:            number;
  Bedrooms:             number;
  Garage:               number;
  Description:          string;
  PlanImages:           PlanImages;
  PlanInteractiveMedia: InteractiveMedia;
  Spec?:                Spec;
}

export interface PlanImages {
  ElevationImage: string[];
  FloorPlanImage: string[] | string;
  VirtualTour:    string;
  PlanViewer:     string;
  InteriorImage?: string[];
}

export interface InteractiveMedia {
  WebsiteURL: string;
  EmbedCode:  string;
}

export enum PlanTypeName {
  SingleFamily = "Single Family",
}

export interface Spec {
  SpecNumber:           number;
  SpecAddress:          SpecAddress;
  SpecMoveInDate:       SpecMoveInDate;
  SpecPrice:            number;
  SpecSqft:             number;
  SpecStories:          number;
  SpecBaths:            number;
  SpecHalfBaths:        number;
  SpecBedrooms:         number;
  SpecGarage:           number;
  SpecDescription:      string;
  SpecImages:           SpecImages;
  SpecInteractiveMedia: InteractiveMedia;
}

export interface SpecAddress {
  SpecLot:     number;
  SpecStreet1: string;
  SpecCity:    City;
  SpecState:   State;
  SpecZIP:     number;
  SpecCountry: Country;
}

export enum City {
  RanchoCordova = "Rancho Cordova",
}

export enum Country {
  Usa = "USA",
}

export enum State {
  CA = "CA",
}

export interface SpecImages {
  SpecElevationImage: string[];
  SpecFloorPlanImage: string[] | string;
  SpecVirtualTour:    string;
  SpecInteriorImage?: string[];
}

export interface SpecMoveInDate {
  [x: string]: string;
}

export interface Promotion {
  PromoHeadline:    string;
  PromoDescription: string;
  PromoURL:         string;
}

export interface SalesOffice {
  Address: Address;
  Phone:   Phone;
  Hours:   string;
}

export interface Address {
  Street1: string;
  City:    City;
  State:   State;
  ZIP:     number;
  Country: Country;
  Geocode: Geocode;
}

export interface Geocode {
  Latitude:  number;
  Longitude: number;
}

export interface Phone {
  AreaCode: number;
  Prefix:   number;
  Suffix:   number;
}

export interface SubAddress {
  SubStreet1: string;
  SubCity:    City;
  SubState:   State;
  SubZIP:     number;
  SubGeocode: SubGeocode;
}

export interface SubGeocode {
  SubLatitude:  number | string;
  SubLongitude: number;
}
