<div id="site-plan" class="siteplan-layout-container">
  <div class="siteplan-title">Plan Your Preferred Homesite</div>
  <div class="siteplan-download-button" (click)="navigateTo(siteplanData.pdf)">
    <span class="siteplan-download-button-label">Download Site Plan</span>
  </div>
  <div class="siteplan-mobile-hint" *ngIf="!isDesktop">
    For optimum experience, please visit this page on your desktop computer.
  </div>
  <div class="siteplan-map-container">
    <div id="map"></div>
    <div *ngIf="isDesktop" class="siteplan-legend" [ngClass]="{ 'siteplan-legend-hidden': !legendExpanded }">
      <div *ngIf="legendExpanded" class="siteplan-legend-inner">
        <div *ngFor="let kv of siteplanData.regions | keyvalue; index as i" class="siteplan-legend-entry">
          <div class="siteplan-legend-swatch" [ngStyle]="{ 'background-color': kv.value.color }"></div>
          <div class="siteplan-legend-label" [ngClass]="getClassesForLabel(kv.value.id)">
            {{ kv.value.id }}
            <span *ngIf="(legendHighlight === kv.value.id) && activeRippleAnim" class="siteplan-legend-ripple"></span>
          </div>
          <div class="siteplan-legend-hint" [ngStyle]="{ 'background-image': 'url(' + tooltipUrl + ')' }"
            (mouseenter)="showPopup(i)" (mouseleave)="hidePopup(i)">
            <div #popups class="siteplan-legend-popup">
              <span>{{ kv.value.info }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="legendExpanded" class="siteplan-legend-toggle" (click)="toggleLegend()">
        <img [src]="legendClose" />
      </div>
      <div *ngIf="!legendExpanded" class="siteplan-legend-open" (click)="toggleLegend()">
        <span class="siteplan-legend-expand-text">Plan Key</span>
      </div>
    </div>
  </div>
  <div *ngIf="!isDesktop" class="siteplan-legend-mobile" [ngClass]="{ 'siteplan-legend-hidden': !legendExpanded }">
    <div class="siteplan-legend-mobile-header" (click)="toggleLegend()"><span>Plan Key</span><span>{{ legendExpanded ?
        '-' : '+' }}</span></div>
    <div *ngIf="legendExpanded" class="siteplan-legend-mobile-content">
      <div *ngFor="let kv of siteplanData.regions | keyvalue; index as i" class="siteplan-legend-mobile-entry">
        <div class="siteplan-legend-mobile-swatch" [style.backgroundColor]="kv.value.color"></div>
        <div class="siteplan-legend-mobile-text-container">
          <div class="siteplan-legend-mobile-entry-title" [ngClass]="getClassesForLabel(kv.value.id)">
            {{ kv.value.id }}
            <span *ngIf="(legendHighlight === kv.value.id) && activeRippleAnim" class="siteplan-legend-ripple"></span>
          </div>
          <div class="siteplan-legend-mobile-entry-description">{{ kv.value.info }}</div>

        </div>

      </div>
    </div>
  </div>
</div>