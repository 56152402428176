<div class="slider-home-full">
    <div class="overlay"></div>
    <video id="bg-video" class="bg-video" loop playsinline>
        <source src="/assets/media/BGVideo_sm.mp4" type="video/mp4">
    </video>
    <div class="hero-content">
        <div class="container" id="fade-home-hero-0">
            <div class="content">
                <div class="content-top">
                    Welcome to
                </div>
                <div class="content-btm">
                    Life on The Ranch
                </div>
                <img class="icon" (click)="watchVideo()" src="/assets/icons/PlayIcon.svg">
            </div>
        </div>
    </div>
</div>