<div class="contain-nav">
  <div class="nav-content">
    <div routerLink="/" class="logo">
      <img src="/assets/logos/TheRanch-Logo_White.svg" />
    </div>
    <div class="desktop-nav">
      <div class="nav-item">
        <span class="link link-txt" routerLinkActive="active" routerLink="/community"
          >Community</span
        >
        <span class="link link-txt" routerLinkActive="active" routerLink="/find-a-home"
          >Find A Home</span
        >
        <span class="link link-txt" routerLinkActive="active" routerLink="/gallery">Gallery</span>
        <span class="link link-txt" routerLinkActive="active" routerLink="/about-overview"
          >About K. Hovnanian</span
        >
      </div>
      <div class="nav-item">
        <span (click)="openSidenav('form')" class="link link-box"
          >Join Our<br class="interest-br" />
          Interest List</span
        >
      </div>
    </div>
    <img
      (click)="openSidenav('menu')"
      class="mobile-icon"
      src="/assets/icons/Mobile-Menu_White.svg"
    />
  </div>
</div>
