<div *ngIf="config" class="view-home-gallery-container">
  <ranch-tab-nav [tabs]="config.tabs" [index]="tabIndex" (onSetTab)="setTabHandler($event)">
    <ranch-gallery-carousel
      *ngIf="tabIndex === 0"
      [images]="config.photos"
    ></ranch-gallery-carousel>
    <ranch-gallery-carousel
      *ngIf="tabIndex === 1"
      [images]="config.floorplanImages"
    ></ranch-gallery-carousel>
  </ranch-tab-nav>
</div>
