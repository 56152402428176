import { Component, OnInit, OnDestroy, OnChanges, ViewEncapsulation } from '@angular/core';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ranch-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy, OnChanges {
  customClass = 'home';
  introHeading: string;
  introCopy: string;
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService, private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.detectScreenSize();
    this.setMeta();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(): void {
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
      this.introHeading = 'A Sprawling Master-Planned Community';
      this.introCopy = 'Break away from the expected and turn to a new way of life. With acres of rolling land on a nature preserve in Rancho Cordova, The Ranch is an ideal place to put down roots for your next chapter. Here you can embrace a more carefree version of yourself without sacrificing the modern conveniences that make each day easier.';
    });
  }

  setMeta(): void {
    this.title.setTitle('The Ranch | New Homes In Rancho Cordova, California');
    this.meta.addTags([
      {
        name: 'description',
        content:
          'With both one- and two-level plans available, this collection offers unmatched variety. Select a design that matches your unique style for a living space that offers up to 2,152 square feet, five bedrooms and a loft.'
      }
    ]);
  }
}
