import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription, tap } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { HeroConfig } from 'src/app/types/hero.types';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { environment } from 'src/environments/environment';
import { HomesService } from './../../services/homes.service';
import { Home } from './../../types/home.type';
import { DESKTOP_FILTERS } from './find-a-home.constants';
import { DesktopFilter, FilterNames, FilterPopupTypes } from './find-a-home.types';

enum SortOption {
  NameAsc = 'Name (A - Z)',
  NameDesc = 'Name (Z - A)',
  PriceAsc = 'Price (Low to High)',
  PriceDesc = 'Price (High to Low)',
  SqFtAsc = 'Sq. Ft. (Low to High)',
  SqFtDesc = 'Sq. Ft. (High to Low)'
}

const transformHomeType = type =>
  ({
    ['Single Family']: 'Single Family Homes',
    ['Townhome']: 'Townhomes'
  }[type]);

@Component({
  selector: 'ranch-find-a-home',
  templateUrl: './find-a-home.component.html',
  styleUrls: ['./find-a-home.component.scss']
})
export class FindAHomeComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    screenService: ScreenSizeService,
    navigationService: NavigationService,
    private homesService: HomesService,
    private platform: PlatformService,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
    private changeDetector: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe
  ) {
    super(screenService, navigationService);
    this.getHeroData();
  }

  public MAX_PER_PAGE: number = 12;
  public currentPage: number = 1;
  public isLeftArrowDisabled: boolean = false;
  public isRightArrowDisabled: boolean = false;

  public hero: HeroConfig;

  public desktopFilters: DesktopFilter[] = DESKTOP_FILTERS;
  public whichDesktopFilterIsOpen: FilterNames = null;
  public currentFilters: Record<string, string> = {};
  public filterNamesEnum = FilterNames;
  public sortOptionEnum = SortOption;
  public sortOptions: SortOption[] = [
    SortOption.NameAsc,
    SortOption.NameDesc,
    SortOption.PriceAsc,
    SortOption.PriceDesc,
    SortOption.SqFtAsc,
    SortOption.SqFtDesc
  ];
  public selectedSort: SortOption = null;
  public isDesktopSortPopupOpen: boolean = false;
  public isMobileFiltersOpen: boolean = false;
  public isMobileSortOpen: boolean = false;

  public homesDataUnfiltered: Home[];
  public homesData: Home[];

  public resultsToShow: Home[];
  public pagesToShow: number[] = [1];

  public mobileFormGroup: FormGroup;

  public isLoading: boolean = false;

  private subs: Subscription[] = [];

  public ngOnInit(): void {
    this.title.setTitle('Find a Home | The Ranch');
    this.getHomesData();
    this.subs.push(
      this.aBreakpointWasCrossed$.pipe(tap(() => this.handleResetFilters())).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private getHomesData(): void {
    this.isLoading = true;
    this.homesService.getHomes().subscribe((data: any) => {
      this.isLoading = false;
      this.homesData = data.Subdivision.reduce((plans, sub) => {
        const plansWithSubData = [...sub.Plan].map(plan => ({
          ...plan,
          SubdivisionData: {
            SubVideoFile: sub.SubVideoFile,
            SubWebsite: sub.SubWebsite,
            SubdivisionName: sub.SubdivisionName,
            SubdivisionNumber: sub.SubdivisionNumber
          }
        }));
        return [...(plans as Home[]), ...(plansWithSubData as Home[])];
      }, []);
      this.homesDataUnfiltered = [...this.homesData];
      this.setResultsToShow();
      this.setPagesToShow();
      this.disableArrowsIfApplicable();
      this.populateFilters();
    });
  }

  private getHeroData(): void {
    this.hero = {
      heroImg: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Desktop+Subpage+Header/FindAHome_Header_Desktop.png`,
      heroImgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Mobile+Subpage+Header/FindAHome_Header_Mobile.png`,
      text: {
        heroText: 'Discover Dozens of<br/>Designs, Find Your<br/>One Perfect Fit'
      },
      mobileText: {
        heroText: 'Discover Dozens of<br/>Designs, Find Your<br/>One Perfect Fit'
      },
      heroClass: 'find-a-home'
    };
  }

  public openDesktopFilter(filterName: FilterNames, event: MouseEvent): void {
    event.stopPropagation();
    this.whichDesktopFilterIsOpen = filterName;
  }

  public closeDesktopFilters(): void {
    this.whichDesktopFilterIsOpen = null;
  }

  public handleClickPlanName(plan: Home): void {
    this.navigateTo(`/find-a-home/${plan.PlanName?.toLowerCase()?.replace(/ /g, '-')}`);
  }

  public setResultsToShow() {
    const index = this.currentPage * this.MAX_PER_PAGE - this.MAX_PER_PAGE;
    this.resultsToShow = this.homesData.slice(index, index + this.MAX_PER_PAGE)?.filter(Boolean);
  }

  public goToPage(page: number): void {
    if (page > 0 && this.isThisPageValid(page)) {
      this.currentPage = page;
      this.setResultsToShow();
      this.setPagesToShow();
      this.disableArrowsIfApplicable();
      if (this.platform.isBrowser()) {
        const wind = this.document.defaultView;
        wind.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  public setPagesToShow(): void {
    let pages;
    if (this.currentPage === 1) {
      pages = [1, 2, 3];
    } else if (!this.isThisPageValid(this.currentPage + 1)) {
      pages = [this.currentPage - 2, this.currentPage - 1, this.currentPage];
    } else {
      pages = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
    }
    pages = pages.filter(p => p >= 1 && this.isThisPageValid(p));
    this.pagesToShow = pages;
  }

  private isThisPageValid(page): boolean {
    if (page === 1) {
      return true;
    } else if (this.homesData.length === 0) {
      return false;
    }
    return (
      !this.homesData.length ||
      Boolean(this.homesData[page * this.MAX_PER_PAGE - this.MAX_PER_PAGE])
    );
  }

  private disableArrowsIfApplicable() {
    if (this.currentPage - 1 < 1 || !this.isThisPageValid(this.currentPage - 1)) {
      this.isLeftArrowDisabled = true;
    } else {
      this.isLeftArrowDisabled = false;
    }
    if (this.currentPage + 1 < 1 || !this.isThisPageValid(this.currentPage + 1)) {
      this.isRightArrowDisabled = true;
    } else {
      this.isRightArrowDisabled = false;
    }
  }

  private populateFilters() {
    const lows = {};
    const highs = {};
    const homeTypes = {};
    this.homesDataUnfiltered.forEach((home: Home) => {
      this.desktopFilters.forEach((desktopFilter: DesktopFilter) => {
        // Price Range
        if (desktopFilter.name === FilterNames.PriceRange) {
          lows[FilterNames.PriceRange] =
            lows[FilterNames.PriceRange] == null || lows[FilterNames.PriceRange] > home.BasePrice
              ? home.BasePrice
              : lows[FilterNames.PriceRange];
          highs[FilterNames.PriceRange] =
            highs[FilterNames.PriceRange] == null || highs[FilterNames.PriceRange] < home.BasePrice
              ? home.BasePrice
              : highs[FilterNames.PriceRange];

          // Beds
        } else if (desktopFilter.name === FilterNames.Beds) {
          lows[FilterNames.Beds] =
            lows[FilterNames.Beds] == null || lows[FilterNames.Beds] > home.Bedrooms
              ? home.Bedrooms
              : lows[FilterNames.Beds];
          highs[FilterNames.Beds] =
            highs[FilterNames.Beds] == null || highs[FilterNames.Beds] < home.Bedrooms
              ? home.Bedrooms
              : highs[FilterNames.Beds];

          // Baths
        } else if (desktopFilter.name === FilterNames.Baths) {
          const baths = home.Baths + home.HalfBaths * 0.5;
          lows[FilterNames.Baths] =
            lows[FilterNames.Baths] == null || lows[FilterNames.Baths] > baths
              ? baths
              : lows[FilterNames.Baths];
          highs[FilterNames.Baths] =
            highs[FilterNames.Baths] == null || highs[FilterNames.Baths] < baths
              ? baths
              : highs[FilterNames.Baths];

          // Sq Ft
        } else if (desktopFilter.name === FilterNames.SqFt) {
          lows[FilterNames.SqFt] =
            lows[FilterNames.SqFt] == null || lows[FilterNames.SqFt] > home.BaseSqft
              ? home.BaseSqft
              : lows[FilterNames.SqFt];
          highs[FilterNames.SqFt] =
            highs[FilterNames.SqFt] == null || highs[FilterNames.SqFt] < home.BaseSqft
              ? home.BaseSqft
              : highs[FilterNames.SqFt];

          // Garages
        } else if (desktopFilter.name === FilterNames.Garages) {
          lows[FilterNames.Garages] =
            lows[FilterNames.Garages] == null || lows[FilterNames.Garages] > home.Garage
              ? home.Garage
              : lows[FilterNames.Garages];
          highs[FilterNames.Garages] =
            highs[FilterNames.Garages] == null || highs[FilterNames.Garages] < home.Garage
              ? home.Garage
              : highs[FilterNames.Garages];

          // Stories
        } else if (desktopFilter.name === FilterNames.Stories) {
          lows[FilterNames.Stories] =
            lows[FilterNames.Stories] == null || lows[FilterNames.Stories] > home.Stories
              ? home.Stories
              : lows[FilterNames.Stories];
          highs[FilterNames.Stories] =
            highs[FilterNames.Stories] == null || highs[FilterNames.Stories] < home.Stories
              ? home.Stories
              : highs[FilterNames.Stories];

          // HomeType
        } else if (desktopFilter.name === FilterNames.HomeType) {
          homeTypes[home.PlanTypeName] = true;
        }
      });
    });

    this.desktopFilters.forEach((desktopFilter: DesktopFilter) => {
      const conf = desktopFilter.popupConfig;
      let current;
      if (desktopFilter.name === FilterNames.PriceRange) {
        conf.options1.push('Any');
        conf.options2.push('Any');
        current = lows[FilterNames.PriceRange] - (lows[FilterNames.PriceRange] % 50000);
        while (current <= highs[FilterNames.PriceRange]) {
          conf.options1.push(this.currencyPipe.transform(current, 'USD', '$', '1.0-0'));
          conf.options2.push(this.currencyPipe.transform(current, 'USD', '$', '1.0-0'));
          current += 50000;
        }
        conf.options1.push(this.currencyPipe.transform(current, 'USD', '$', '1.0-0'));
        conf.options2.push(this.currencyPipe.transform(current, 'USD', '$', '1.0-0'));
      } else if (desktopFilter.name === FilterNames.Beds) {
        conf.options.push('Any');
        current = lows[FilterNames.Beds];
        while (current <= highs[FilterNames.Beds]) {
          conf.options.push(`${current}+`);
          current += 1;
        }
      } else if (desktopFilter.name === FilterNames.Baths) {
        conf.options.push('Any');
        current = lows[FilterNames.Baths];
        while (current <= highs[FilterNames.Baths]) {
          conf.options.push(`${current}+`);
          current += 0.5;
        }
      } else if (desktopFilter.name === FilterNames.SqFt) {
        conf.options1.push('Any');
        conf.options2.push('Any');
        current = lows[FilterNames.SqFt] - (lows[FilterNames.SqFt] % 500);
        while (current <= highs[FilterNames.SqFt]) {
          conf.options1.push(this.currencyPipe.transform(current, 'USD', '', '1.0-0'));
          conf.options2.push(this.currencyPipe.transform(current, 'USD', '', '1.0-0'));
          current += 500;
        }
        conf.options1.push(this.currencyPipe.transform(current, 'USD', '', '1.0-0'));
        conf.options2.push(this.currencyPipe.transform(current, 'USD', '', '1.0-0'));
      } else if (desktopFilter.name === FilterNames.Garages) {
        conf.options.push('Any');
        current = lows[FilterNames.Garages];
        while (current <= highs[FilterNames.Garages]) {
          conf.options.push(`${current}+ Car Garage`);
          current += 1;
        }
      } else if (desktopFilter.name === FilterNames.Stories) {
        conf.options.push('Any');
        current = lows[FilterNames.Stories];
        while (current <= highs[FilterNames.Stories]) {
          conf.options.push(`${current} Stor${current === 1 ? 'y' : 'ies'}`);
          current += 1;
        }
      } else if (desktopFilter.name === FilterNames.HomeType) {
        conf.options = [
          'All Home Types',
          ...Object.keys(homeTypes).map(it => transformHomeType(it))
        ];
      }
    });
  }

  // This IS used, called dynamically
  private aggregateSubdivisions() {
    let alreadyFound = {};
    if (!this.homesDataUnfiltered || !this.homesDataUnfiltered?.length) return [];
    return [
      'Any Community',
      ...this.homesDataUnfiltered
        .reduce((subs, home) => {
          return [...subs, home.SubdivisionData.SubdivisionName];
        }, [])
        ?.filter(it => {
          // Remove duplicates
          let found = alreadyFound[it];
          alreadyFound[it] = true;
          return !found;
        })
    ];
  }

  public callOptionFetcher(methodName) {
    return this[methodName]?.();
  }

  public handleSelectInRange(event: any) {
    if (['any', 'no min', 'no max'].includes(event.target.value?.toLowerCase())) {
      this.currentFilters[event.target.name] = undefined;
    } else {
      this.currentFilters[event.target.name] = event.target.value;
    }
    this.applyFilteringAndSorting();
  }

  public handleClickFilterListItem(filterName: FilterNames, optionValue: string) {
    this.currentFilters[filterName] = optionValue;
    this.closeDesktopFilters();
    this.applyFilteringAndSorting();
  }

  public transformLabelForTerse(label: string) {
    return {
      beds: ' Bd',
      baths: ' Ba'
    }[label.toLowerCase()];
  }

  public transformValueForTerse(value: string, filterName: string = null) {
    if (value.match(/[0-9]+\+? car garage/i)) {
      return value.replace(/ garage$/i, '');
    } else if (value.toLowerCase() === 'single family homes') {
      return 'Single Family';
    } else if (value?.toLowerCase().endsWith(' at the ranch')) {
      return value.replace(/ at the ranch$/i, '');
    } else if (value?.match(/from \$[0-9,]+ to \$[0-9,]+/i)) {
      return value
        ?.replace(' to ', ' - ')
        ?.replace('from ', '')
        ?.replace(/,000/g, 'k')
        .replace(/,500/g, '.5k');
    } else if (filterName?.toLowerCase() === FilterNames.SqFt?.toLowerCase()) {
      let newValue = value?.replace(/,000/g, 'k').replace(/,500/g, '.5k');
      if (value?.includes('from ') && !value?.includes(' to ')) {
        return `${newValue.replace('from ', '')}+ SF`;
      } else if (!value?.includes('from ') && value?.includes(' to ')) {
        return `<${newValue.replace(' to ', '')} SF`;
      } else if (value?.includes('from ') && value?.includes(' to ')) {
        return `${newValue.replace('from ', '')?.replace(' to ', '-')} SF`;
      } else {
        return newValue;
      }
    }

    return value;
  }

  public handleResetFilters() {
    this.currentFilters = {};
    this.applyFilteringAndSorting();
  }

  public handleClickCheckboxFilter(filterName: FilterNames) {
    //TODO: add checkboxes back in?
    // this.currentFilters[filterName] = !this.currentFilters[filterName];
    this.applyFilteringAndSorting();
  }

  public applyFilteringAndSorting() {
    if (!this.homesDataUnfiltered) return;
    const newHomesData = this.homesDataUnfiltered.filter((home: Home) => {
      if (this.currentFilters[FilterNames.PriceRange + '-dropdown-1']) {
        const asNum = Number(
          `${this.currentFilters[FilterNames.PriceRange + '-dropdown-1']}`
            .replace(/\$/g, '')
            ?.replace(/,/g, '')
        );
        if (home.BasePrice < asNum) return false;
      }
      if (this.currentFilters[FilterNames.PriceRange + '-dropdown-2']) {
        const asNum = Number(
          `${this.currentFilters[FilterNames.PriceRange + '-dropdown-2']}`
            .replace(/\$/g, '')
            ?.replace(/,/g, '')
        );
        if (home.BasePrice > asNum) return false;
      }
      if (this.currentFilters[FilterNames.Beds]) {
        const asNum = Number((this.currentFilters[FilterNames.Beds] as string).replace(/\+/g, ''));
        if (home.Bedrooms < asNum) return false;
      }
      if (this.currentFilters[FilterNames.Baths]) {
        const asNum = Number((this.currentFilters[FilterNames.Baths] as string).replace(/\+/g, ''));
        const totalBaths = home.Baths + home.HalfBaths * 0.5;
        if (totalBaths < asNum) return false;
      }
      if (this.currentFilters[FilterNames.SqFt + '-dropdown-1']) {
        const asNum = Number(
          `${this.currentFilters[FilterNames.SqFt + '-dropdown-1']}`?.replace(/,/g, '')
        );
        if (home.BaseSqft < asNum) return false;
      }
      if (this.currentFilters[FilterNames.SqFt + '-dropdown-2']) {
        const asNum = Number(
          `${this.currentFilters[FilterNames.SqFt + '-dropdown-2']}`?.replace(/,/g, '')
        );
        if (home.BaseSqft > asNum) return false;
      }
      if (this.currentFilters[FilterNames.Garages]) {
        const asNum = Number(
          (this.currentFilters[FilterNames.Garages] as string).match(/^[0-9]+/)?.[0]
        );
        if (home.Garage < asNum) return false;
      }
      if (this.currentFilters[FilterNames.Stories]) {
        const asNum = Number(`${this.currentFilters[FilterNames.Stories]}`.match(/^[0-9\.]+/)?.[0]);
        if (home.Stories !== asNum) return false;
      }
      if (this.currentFilters[FilterNames.HomeType]) {
        return (
          this.currentFilters[FilterNames.HomeType]?.toLowerCase() === 'all home types' ||
          transformHomeType(home.PlanTypeName) === this.currentFilters[FilterNames.HomeType]
        );
      }
      if (this.currentFilters[FilterNames.Community]) {
        return (
          this.currentFilters[FilterNames.Community]?.toLowerCase() === 'any community' ||
          home.SubdivisionData.SubdivisionName === this.currentFilters[FilterNames.Community]
        );
      }
      // TODO: do we need these? Nothing in the data to use...
      // if (this.currentFilters[FilterNames.ExtraSuite]) {
      // }
      // if (this.currentFilters[FilterNames.MainLevelOwners]) {
      // }

      return true;
    });
    newHomesData.sort((a, b) => {
      if (this.selectedSort === SortOption.NameAsc) {
        return a.PlanName.localeCompare(b.PlanName);
      } else if (this.selectedSort === SortOption.NameDesc) {
        return b.PlanName.localeCompare(a.PlanName);
      } else if (this.selectedSort === SortOption.PriceAsc) {
        return a.BasePrice - b.BasePrice;
      } else if (this.selectedSort === SortOption.PriceDesc) {
        return b.BasePrice - a.BasePrice;
      } else if (this.selectedSort === SortOption.SqFtAsc) {
        return a.BaseSqft - b.BaseSqft;
      } else if (this.selectedSort === SortOption.SqFtDesc) {
        return b.BaseSqft - a.BaseSqft;
      } else {
        return 0;
      }
    });
    this.homesData = newHomesData;
    this.goToPage(1);
    this.changeDetector.detectChanges();
  }

  public toggleDesktopSortPopup() {
    this.isDesktopSortPopupOpen = !this.isDesktopSortPopupOpen;
  }

  public closeDesktopSortPopup() {
    this.isDesktopSortPopupOpen = false;
  }

  public handleSelectSortOption(option: SortOption) {
    this.selectedSort = option;
    this.closeDesktopSortPopup();
    this.applyFilteringAndSorting();
  }

  openMobileFilters() {
    this.isMobileFiltersOpen = true;
    this.document.body.style.position = 'fixed';
  }

  closeMobileFilters() {
    this.isMobileFiltersOpen = false;
    this.document.body.style.position = 'initial';
  }

  openMobileSort() {
    this.isMobileSortOpen = true;
    this.document.body.style.position = 'fixed';
  }

  closeMobileSort() {
    this.isMobileSortOpen = false;
    this.document.body.style.position = 'initial';
  }

  getNonListFilters(): Array<DesktopFilter> {
    return this.desktopFilters.filter(elem => elem.popupConfig.type !== FilterPopupTypes.List);
  }

  getListFilters(): Array<DesktopFilter> {
    return this.desktopFilters.filter(elem => elem.popupConfig.type === FilterPopupTypes.List);
  }

  onFiltersSeeResultsClick(): void {
    this.closeMobileFilters();
    this.applyFilteringAndSorting();
    this.changeDetector.detectChanges();
  }

  getFilterOptionsForRange(filter: DesktopFilter, type: 'min' | 'max'): Array<string> {
    const opt2num = (opt: string) => opt.replace('$', '').replace(',', '');
    let ret = type === 'min' ? filter.popupConfig.options1 : filter.popupConfig.options2;
    const oppositeValue = this.currentFilters[`${filter.name}-dropdown-${type === 'min' ? 2 : 1}`];
    if (oppositeValue) {
      ret = ret.filter(val => {
        if (val.toLowerCase() === 'any') return true;
        if (type === 'min' && opt2num(val) < opt2num(oppositeValue)) return true;
        if (type === 'max' && opt2num(val) > opt2num(oppositeValue)) return true;
        return false;
      });
    }
    if (ret.length === 1 && ret[0]?.toLowerCase() === 'any') {
      return ['NO ' + (type === 'min' ? 'MIN' : 'MAX')];
    }
    return ret;
  }
}
