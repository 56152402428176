import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GalleryConfig, GalleryData } from 'src/app/data/view-home.data';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { HeroConfig } from 'src/app/types/hero.types';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { environment } from 'src/environments/environment';
import { HomesService } from './../../../../services/homes.service';
import { Home, SubdivisionMedia } from './../../../../types/home.type';
import { truncate } from './../../../../utils/truncate.utils';
import {
  DESKTOP_SPAN_1_STYLES,
  DESKTOP_SPAN_2_STYLES,
  DESKTOP_SPAN_3_STYLES,
  DESKTOP_SPAN_4_STYLES,
  DESKTOP_UNDERLINE_STYLE,
  SPAN_1_STYLES,
  SPAN_2_STYLES,
  SPAN_3_STYLES,
  SPAN_4_STYLES,
  UNDERLINE_STYLE
} from './view-home.styles';

@Component({
  selector: 'ranch-view-home',
  templateUrl: './view-home.component.html',
  styleUrls: ['./view-home.component.scss']
})
export class ViewHomeComponent extends ComponentBase implements OnInit {
  constructor(
    private homesService: HomesService,
    private currencyPipe: CurrencyPipe,
    private domSanitizer: DomSanitizer,
    private screenService: ScreenSizeService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  public data: Home & SubdivisionMedia;

  public hero: HeroConfig;

  public galleryConfig: GalleryConfig | undefined;

  public includeReadMore: boolean = false;

  public isReadMoreOpen: boolean = false;

  private fetchData() {
    this.route.url.subscribe(obs => {
      this.homesService.getHomeByPlanName(obs[0].path).subscribe(data => {
        if (data === undefined || data === null) {
          return this.navigateTo('/find-a-home');
        }
        this.data = data;
        console.log(this.data);
        this.getHeroData();
        this.setGalleryConfig();
      });
    });
  }

  private getHeroData(): void {
    const heroText = this.isDesktop
      ? `
        <span style="${DESKTOP_SPAN_1_STYLES}">${this.data.PlanName}</span>
    <br/>
    <span style="${DESKTOP_SPAN_2_STYLES}">${this.data.PlanTypeName?.toUpperCase()} HOME</span>
    <br/>
    <span style="${DESKTOP_SPAN_3_STYLES}">STARTING FROM ${this.transformPrice(
          this.data.BasePrice
        )}</span>
    <br/>
    <span style="${DESKTOP_SPAN_4_STYLES}">${this.data.SubdivisionName}</span>
    <br>
    <div style="${DESKTOP_UNDERLINE_STYLE}"></div>

    `
      : `
    <span style="${SPAN_1_STYLES}">${this.data.PlanName}</span>
    <br/>
    <span style="${SPAN_2_STYLES}">${this.data.PlanTypeName?.toUpperCase()} HOME</span>
    <br/>
    <span style="${SPAN_3_STYLES}">STARTING FROM ${this.transformPrice(this.data.BasePrice)}</span>
    <br/>
    <span style="${SPAN_4_STYLES}">${this.data.SubdivisionName}</span>
    <br>
    <div style="${UNDERLINE_STYLE}"></div>
    `;
    this.hero = {
      heroImg: `${this.data.PlanImages.InteriorImage?.[0] ||
        this.data.PlanImages.ElevationImage?.[0]}`,
      heroImgMobile: `${this.data.PlanImages.InteriorImage?.[0] ||
        this.data.PlanImages.ElevationImage?.[0]}`,
      text: {
        heroText: heroText
      },
      mobileText: {
        heroText: heroText
      },
      heroClass: 'view-home-hero'
    };
  }

  transformPrice(price: number): string {
    return this.currencyPipe.transform(price, 'USD', 'symbol', '1.0-0');
  }

  getDescription(includeFull: boolean = false): SafeHtml {
    const truncated = includeFull
      ? this.data?.Description || ''
      : truncate(200, this.data?.Description || '');
    let includeReadMore = false;
    if (truncated.length < this.data?.Description?.length) {
      includeReadMore = true;
    }
    let withLineBreaks = truncated;
    while (withLineBreaks.includes('\n')) {
      withLineBreaks = withLineBreaks?.replace('\n', '<br/><br/>');
    }
    this.includeReadMore = includeReadMore;
    return this.domSanitizer.bypassSecurityTrustHtml(withLineBreaks);
  }

  setGalleryConfig() {
    this.galleryConfig = { ...GalleryData };
    if (this.data && this.data.PlanImages && this.data.PlanImages.FloorPlanImage) {
      this.galleryConfig = {
        ...this.galleryConfig,
        photos: [
          ...(this.data.PlanImages.ElevationImage || []),
          ...(this.data.PlanImages.InteriorImage || [])
        ].map((it, idx) => ({ src: it as string, caption: '' as string })),
        floorplanImages: Array.isArray(this.data.PlanImages.FloorPlanImage)
          ? this.data.PlanImages.FloorPlanImage.map((it, idx) => ({
              src: it,
              caption: ''
            }))
          : [{ src: this.data.PlanImages.FloorPlanImage, caption: '' }]
      };
    }
  }

  handleClickReadMore() {
    this.isReadMoreOpen = true;
  }

  prepareForUrl(string: string): string {
    return string.replace(/ /g, '-');
  }

  goToDetailsOnKhovDotCom() {
    const url = `${environment.KHOV_SITE_STEM}/${this.prepareForUrl(
      this.data.SubdivisionName
    )}/${this.prepareForUrl(this.data.PlanName)}`;
    this.navigateTo(url);
  }

  handleClickBackButton() {
    this.navigationService.goBack();
  }
}
