<mat-sidenav-container hasBackdrop="true">
  <mat-sidenav #sidenav [ngClass]=this.menuType mode="over" [fixedInViewport]="true" [(opened)]="opened" position="end"
    (openedStart)="lockScroll('open')" (closedStart)="lockScroll('closed')">
    <ranch-menu-interest type (closeModal)="sidenav.toggle()"
      (closeModal)="receiveMessage($event)"></ranch-menu-interest>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="main-app" [ngClass]="{ 
    freeze : freeze,
    opaque : opaque
     }">
      <div class="main-app-content">
        <div class="navigation-container" [ngClass]="{
      'scrolled-past': scrolledPast || !isHome
    }">
          <ranch-navigation #navigation (openToggleChange)="receiveMessage($event)"></ranch-navigation>
        </div>
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="!isDesktop" class="interest-button">
        <span class="interest-button-label" (click)="receiveMessage('form')">Join the Interest List</span>
      </div>
      <div class="footer-container">
        <ranch-footer></ranch-footer>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>