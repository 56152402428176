<ranch-hero [hero]="hero"></ranch-hero>
<!--Consider adding (click)="closeDesktopFilters()" to container but then
popups get fidgety and would need addressing-->
<div class="fah-content-container">
  <div *ngIf="!isDesktop" class="fah-filter-and-sort-container">
    <button (click)="openMobileFilters()">Filters</button>
    <button (click)="openMobileSort()">Sort By</button>
  </div>
  <div *ngIf="isDesktop" class="fah-filters-container">
    <div *ngFor="let desktopFilter of desktopFilters" class="fah-filter-item">
      <img
        *ngIf="!desktopFilter.isCheckbox"
        src="/assets/icons/ArrowLeft_Dark.svg"
        alt="select-arrow"
        (click)="openDesktopFilter(desktopFilter.name, $event)"
      />
      <select
        *ngIf="!desktopFilter.isCheckbox"
        [name]="desktopFilter.name"
        (click)="openDesktopFilter(desktopFilter.name, $event)"
        [style]="
          'width: ' +
          (desktopFilter.name?.toLowerCase() === filterNamesEnum.SqFt?.toLowerCase() &&
          currentFilters[desktopFilter.name + '-dropdown-1'] &&
          currentFilters[desktopFilter.name + '-dropdown-2']
            ? desktopFilter.width * 1.5
            : desktopFilter.width) +
          'px;'
        "
        onmousedown="(function(e){ e.preventDefault(); })(event, this)"
      >
        <option selected>
          <ng-container
            *ngIf="
              !currentFilters[desktopFilter.name + '-dropdown-2'] &&
              desktopFilter.popupConfig.type === 'range'
            "
          >
            {{
              transformValueForTerse(
                currentFilters[desktopFilter.name + '-dropdown-1']
                  ? ' from ' + currentFilters[desktopFilter.name + '-dropdown-1']
                  : '',
                desktopFilter.name
              )
            }}
          </ng-container>
          <ng-container
            *ngIf="
              !currentFilters[desktopFilter.name + '-dropdown-1'] &&
              desktopFilter.popupConfig.type === 'range'
            "
          >
            {{
              transformValueForTerse(
                currentFilters[desktopFilter.name + '-dropdown-2']
                  ? ' to ' + currentFilters[desktopFilter.name + '-dropdown-2']
                  : '',
                desktopFilter.name
              )
            }}
          </ng-container>
          <ng-container
            *ngIf="
              currentFilters[desktopFilter.name + '-dropdown-1'] &&
              currentFilters[desktopFilter.name + '-dropdown-2'] &&
              desktopFilter.popupConfig.type === 'range'
            "
          >
            {{
              transformValueForTerse(
                ' from ' +
                  currentFilters[desktopFilter.name + '-dropdown-1'] +
                  ' to ' +
                  currentFilters[desktopFilter.name + '-dropdown-2'],
                desktopFilter.name
              )
            }}
          </ng-container>
          <ng-container
            *ngIf="
              desktopFilter.popupConfig.type === 'range' &&
              !currentFilters[desktopFilter.name + '-dropdown-1'] &&
              !currentFilters[desktopFilter.name + '-dropdown-2']
            "
          >
            {{ desktopFilter.label }}
          </ng-container>
          <ng-container *ngIf="desktopFilter.popupConfig.type !== 'range'">
            {{
              currentFilters[desktopFilter.name]
                ? transformLabelForTerse(desktopFilter.label)
                  ? currentFilters[desktopFilter.name] + transformLabelForTerse(desktopFilter.label)
                  : transformValueForTerse(currentFilters[desktopFilter.name])
                : desktopFilter.label
            }}
          </ng-container>
        </option>
      </select>
      <div
        class="fah-filter-popup {{ desktopFilter.popupConfig.type }} {{
          whichDesktopFilterIsOpen === desktopFilter.name ? '' : 'hidden'
        }}"
      >
        <img
          src="/assets/icons/IconClose_Color.svg"
          alt="close"
          (click)="closeDesktopFilters()"
          *ngIf="desktopFilter.popupConfig.type !== 'list'"
        />
        <div class="fah-filter-popup-label" *ngIf="desktopFilter.popupConfig.type !== 'list'">
          {{ desktopFilter.label }}
        </div>
        <div
          *ngIf="desktopFilter.popupConfig.type === 'range'"
          class="fah-filter-popup-range-container"
        >
          <div class="fah-filter-popup-range-item">
            <img src="/assets/icons/ArrowLeft_Dark.svg" alt="select-arrow" />
            <select
              [name]="desktopFilter.name + '-dropdown-1'"
              (change)="handleSelectInRange($event)"
            >
              <option
                *ngFor="let option of getFilterOptionsForRange(desktopFilter, 'min')"
                [selected]="currentFilters[desktopFilter.name] === option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <span> to </span>
          <div class="fah-filter-popup-range-item">
            <img src="/assets/icons/ArrowLeft_Dark.svg" alt="select-arrow" /><select
              [name]="desktopFilter.name + '-dropdown-2'"
              (change)="handleSelectInRange($event)"
            >
              <option
                *ngFor="let option of getFilterOptionsForRange(desktopFilter, 'max')"
                [selected]="currentFilters[desktopFilter.name] === option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div
          *ngIf="desktopFilter.popupConfig.type === 'list'"
          class="fah-filter-popup-list-container"
        >
          <img
            src="/assets/icons/ArrowLeft_Dark.svg"
            alt="select-arrow"
            (click)="closeDesktopFilters()"
          />
          <div class="fah-filter-popup-list-option label" (click)="closeDesktopFilters()">
            {{ desktopFilter.label }}
          </div>
          <ng-container *ngIf="desktopFilter.popupConfig.options?.length">
            <div
              *ngFor="let option of desktopFilter.popupConfig.options; index as index"
              class="fah-filter-popup-list-option {{
                currentFilters[desktopFilter.name] === option ? 'selected' : ''
              }}"
              (click)="handleClickFilterListItem(desktopFilter.name, option)"
            >
              {{ option }}
            </div>
          </ng-container>
          <ng-container *ngIf="desktopFilter.popupConfig.getOptions">
            <div
              *ngFor="
                let option of callOptionFetcher(desktopFilter.popupConfig.getOptions);
                index as index
              "
              class="fah-filter-popup-list-option {{
                currentFilters[desktopFilter.name] === option ? 'selected' : ''
              }}"
              (click)="handleClickFilterListItem(desktopFilter.name, option)"
            >
              {{ option }}
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="desktopFilter.popupConfig.type === 'boxes'"
          class="fah-filter-popup-boxes-container"
        >
          <div
            *ngFor="let option of desktopFilter.popupConfig.options; index as index"
            class="fah-filter-popup-boxes-option {{
              currentFilters[desktopFilter.name] === option ? 'selected' : ''
            }}"
            (click)="handleClickFilterListItem(desktopFilter.name, option)"
          >
            {{ option }}
          </div>
        </div>
      </div>
      <div *ngIf="desktopFilter.isCheckbox" class="fah-filters-checkbox-item">
        <img
          (click)="handleClickCheckboxFilter(desktopFilter.name)"
          src="{{ S3_URL }}{{
            this.currentFilters[desktopFilter.name]
              ? 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Selected.svg'
              : 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Unselected.svg'
          }}"
          alt="check"
        /><span>{{ desktopFilter.label }}</span>
      </div>
    </div>
    <div class="fah-filters-reset-text" (click)="[handleResetFilters(), closeDesktopFilters()]">
      Reset Filters
    </div>
  </div>
  <div class="mf-container" *ngIf="!isDesktop && isMobileFiltersOpen">
    <img
      src="/assets/icons/IconClose_Color.svg"
      alt="close icon"
      class="mf-close-icon"
      (click)="closeMobileFilters()"
    />
    <div class="mf-header">Filter Your<br />Home Design Search</div>
    <div class="mf-filters-container">
      <div *ngFor="let desktopFilter of getNonListFilters()" class="mf-filter-item">
        <div class="mf-filter-popup {{ desktopFilter.popupConfig.type }}">
          <div class="mf-filter-popup-label" *ngIf="desktopFilter.popupConfig.type !== 'list'">
            {{ desktopFilter.label }}
          </div>
          <div
            *ngIf="desktopFilter.popupConfig.type === 'range'"
            class="mf-filter-popup-range-container"
          >
            <div class="mf-filter-popup-range-item">
              <mat-select
                [(value)]="currentFilters[desktopFilter.name + '-dropdown-1']"
                [placeholder]="desktopFilter.popupConfig.placeholder"
              >
                <mat-option
                  *ngFor="let option of getFilterOptionsForRange(desktopFilter, 'min')"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-select>
            </div>
            <span> to </span>
            <div class="mf-filter-popup-range-item">
              <mat-select
                [(value)]="currentFilters[desktopFilter.name + '-dropdown-2']"
                [placeholder]="desktopFilter.popupConfig.placeholder"
              >
                <mat-option
                  *ngFor="let option of getFilterOptionsForRange(desktopFilter, 'max')"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div
            *ngIf="desktopFilter.popupConfig.type === 'list'"
            class="mf-filter-popup-list-container"
          >
            <mat-select
              [(value)]="currentFilters[desktopFilter.name]"
              [placeholder]="desktopFilter.popupConfig.placeholder"
            >
              <mat-option
                *ngFor="let option of desktopFilter.popupConfig.options; index as i"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </div>
          <div
            *ngIf="desktopFilter.popupConfig.type === 'boxes'"
            class="mf-filter-popup-boxes-container"
          >
            <div
              *ngFor="let option of desktopFilter.popupConfig.options; index as index"
              class="mf-filter-popup-boxes-option {{
                currentFilters[desktopFilter.name] === option ||
                desktopFilter.placeholder === option
                  ? 'selected'
                  : ''
              }}"
              (click)="handleClickFilterListItem(desktopFilter.name, option)"
            >
              {{ option }}
            </div>
          </div>
        </div>
        <div *ngIf="desktopFilter.isCheckbox" class="mf-filters-checkbox-item">
          <img
            (click)="handleClickCheckboxFilter(desktopFilter.name)"
            src="{{ S3_URL }}{{
              this.currentFilters[desktopFilter.name]
                ? 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Selected.svg'
                : 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Unselected.svg'
            }}"
            alt="check"
          /><span>{{ desktopFilter.label }}</span>
        </div>
      </div>
      <div class="mf-filters-list-container">
        <div
          *ngFor="let desktopFilter of getListFilters()"
          class="mf-filter-item list {{ desktopFilter.isFullWidth ? 'full-width' : '' }}"
          [style.width]="desktopFilter.width || 'inherit'"
        >
          <div class="mf-filter-label">
            {{ desktopFilter.label }}
          </div>
          <mat-select
            [(value)]="currentFilters[desktopFilter.name]"
            [placeholder]="desktopFilter.popupConfig.placeholder"
          >
            <ng-container *ngIf="!!desktopFilter.popupConfig.getOptions">
              <mat-option
                *ngFor="let option of callOptionFetcher(desktopFilter.popupConfig.getOptions)"
                [value]="option"
                >{{ option }}</mat-option
              >
            </ng-container>
            <ng-container *ngIf="!desktopFilter.popupConfig.getOptions">
              <mat-option *ngFor="let option of desktopFilter.popupConfig.options" [value]="option">
                {{ option }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="mf-button-container">
      <div class="mf-button" (click)="onFiltersSeeResultsClick()"><span>See Results</span></div>
      <div class="mf-button" (click)="handleResetFilters()"><span>Reset</span></div>
    </div>
  </div>
  <div
    *ngIf="isMobileSortOpen"
    class="ms-list-items-sort-popup"
    onclick="(function(e){ e.stopPropagation(); })(event, this)"
  >
    <img
      src="/assets/icons/IconClose_Color.svg"
      alt="close icon"
      class="ms-close-icon"
      (click)="closeMobileSort()"
    />
    <div class="ms-list-items-sort-popup-header">Sort By</div>
    <div
      *ngFor="let option of sortOptions"
      class="ms-list-items-sort-popup-item {{ selectedSort === option ? 'selected' : '' }}"
      (click)="handleSelectSortOption(option)"
    >
      {{ option }}
    </div>
    <div class="ms-button-container">
      <div class="ms-button" (click)="closeMobileSort()">
        <span>See Results</span>
      </div>
    </div>
  </div>
  <div *ngIf="!isDesktop" class="fah-mobile-pagination-top-text">
    Showing {{ resultsToShow?.length || 0 }} out of {{ homesData?.length }} Home Designs
  </div>
  <div class="fah-list-items-container">
    <div class="fah-list-items-pagination-and-sort-container">
      <div *ngIf="isDesktop" class="fah-list-items-desktop-pagination">
        Showing {{ resultsToShow?.length || 0 }} out of {{ homesData?.length }} Home Designs
      </div>
      <div *ngIf="isDesktop" class="fah-list-items-desktop-sort" (click)="toggleDesktopSortPopup()">
        <span>{{ selectedSort || 'Sort' }}</span
        ><img
          src="/assets/icons/ArrowLeft_Dark.svg"
          alt="arrow"
          [class.reverse]="isDesktopSortPopupOpen"
        />
        <div
          *ngIf="isDesktopSortPopupOpen"
          class="fah-list-items-desktop-sort-popup"
          onclick="(function(e){ e.stopPropagation(); })(event, this)"
        >
          <div
            *ngFor="let option of sortOptions"
            class="fah-list-items-desktop-sort-popup-item {{
              selectedSort === option ? 'selected' : ''
            }}"
            (click)="handleSelectSortOption(option)"
          >
            {{ option }}
          </div>
        </div>
      </div>
    </div>
    <div class="fah-list-items">
      <div class="fah-no-results" *ngIf="!resultsToShow?.length">
        {{ isLoading ? 'Loading...' : 'No Results' }}
      </div>
      <div class="fah-list-item" *ngFor="let plan of resultsToShow">
        <img class="fah-list-item-image" [src]="plan.PlanImages.ElevationImage[0]" />
        <div class="fah-list-item-content">
          <div class="fah-list-item-name" (click)="handleClickPlanName(plan)">
            {{ plan.PlanName }}<img src="/assets/icons/ArrowRight_Dark.svg" alt="arrow" />
          </div>
          <div class="fah-list-item-subdivision">{{ plan.SubdivisionData.SubdivisionName }}</div>
          <div class="fah-list-item-price">
            Starting from {{ plan.BasePrice | currency: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="fah-list-item-beds-and-baths">
            <div class="fah-list-item-beds">{{ plan.Bedrooms }} Beds</div>
            <div class="fah-list-item-baths">{{ plan.Baths + plan.HalfBaths * 0.5 }} Baths</div>
          </div>
          <div class="fah-list-item-sq-ft">{{ plan.BaseSqft | number: '1.0-0' }} sq. ft.</div>
          <div class="fah-list-item-garage">{{ plan.Garage }}-Car Garage</div>
        </div>
      </div>
    </div>
  </div>
  <div class="fah-list-items-mobile-pagination">
    <div class="fah-list-items-mobile-pagination-text">
      Showing {{ resultsToShow?.length || 0 }} out of {{ homesData?.length }} Home Designs
    </div>
    <div class="fah-list-items-mobile-pagination-numbers">
      <img
        src="/assets/icons/ArrowLeft_Dark.svg"
        alt="left arrow"
        class="fah-list-items-mobile-pagination-arrow left {{
          isLeftArrowDisabled ? 'disabled' : ''
        }}"
        (click)="isLeftArrowDisabled ? null : goToPage(currentPage - 1)"
      />
      <div
        *ngFor="let page of pagesToShow"
        class="fah-list-items-mobile-pagination-number {{ page === currentPage ? 'active' : '' }}"
        (click)="goToPage(page)"
      >
        <div>{{ page > 9 ? page : '0' + page }}</div>
      </div>
      <img
        src="/assets/icons/ArrowRight_Dark.svg"
        alt="right arrow"
        class="fah-list-items-mobile-pagination-arrow right {{
          isRightArrowDisabled ? 'disabled' : ''
        }}"
        (click)="isRightArrowDisabled ? null : goToPage(currentPage + 1)"
      />
    </div>
  </div>
</div>
