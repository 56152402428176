import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { GalleryPreviewConfig, GalleryPreviewData } from 'src/app/data/gallery.data';

@Component({
  selector: 'ranch-home-gallery-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class RanchHomeGalleryPreviewComponent extends ComponentBase implements OnInit {
  showAmenities = false;
  slideConfig: SwiperConfigInterface;
  slideData: GalleryPreviewConfig;
  S3_URL: string = environment.S3_URL;
  
  constructor(
    private platform: PlatformService,
    private screenService: ScreenSizeService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
    this.setSlider();
  }

  setSlider(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      loop: true,
      autoHeight: false,
      centeredSlides: true,
      effect: 'fade',
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet(index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }
      }
    };
    this.slideData = GalleryPreviewData;
  }
  onIndexChange(event) {

  }
}
