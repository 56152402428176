import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BDXResponsePayload, Home, Subdivision, SubdivisionMedia } from '../types/home.type';

@Injectable({
  providedIn: 'root'
})
export class HomesService {
  constructor(private http: HttpClient) {}
  payload$: BehaviorSubject<BDXResponsePayload> = new BehaviorSubject(null);
  
  getHomes(): Observable<Array<BDXResponsePayload>> {
    return this.http.get<Array<BDXResponsePayload>>(`${environment.BDX_URL}`);
  }

  setPayload(payload: BDXResponsePayload): void {
    this.payload$.next(payload);
  }

  getHomeByPlanName(name: string): Observable<Home & SubdivisionMedia> {
    return this.http.get<BDXResponsePayload>(`${environment.BDX_URL}`).pipe(map(e => {
      const subdivision = e.Subdivision.find(s => s.Plan.find(p => p.PlanName.toLowerCase() === name) !== undefined);
      if (!subdivision) return null;
      const home = subdivision.Plan.find(p => p.PlanName.toLowerCase() === name);
      console.log({ subdivision, home})
      return {
        ...home,
        SubVideoFile: subdivision.SubVideoFile,
        SubWebsite: subdivision.SubWebsite,
        SubdivisionName: subdivision.SubdivisionName,
        SubdivisionNumber: subdivision.SubdivisionNumber
      } as Home & SubdivisionMedia;
    }))
  }

  clearPayload(): void {
    this.payload$.next(null);
  }
}
