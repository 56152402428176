import { Component, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoModalComponent } from '../../features/home/presentational/video-modal/video-modal.component';
import { ScreenSizeService } from '../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-full-screen-slider',
  templateUrl: './full-screen-slider.component.html',
  styleUrls: ['./full-screen-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullScreenSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private screenService: ScreenSizeService,
    private platform: PlatformService
  ) {
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.setFadeAnimations();
  }

  ngAfterViewInit(): void {
    this.triggerVideo();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  watchVideo(): void {
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.panelClass = 'video-player';
    modalConfig.backdropClass = 'backdrop-dark';
    const modalRef = this.dialog.open(VideoModalComponent, modalConfig);
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
  }

  setFadeAnimations(): void {
    this.addFade('fade-home-hero-0');
  }

  addFade(id): void {
    const el = document.getElementById(id);
    if (el) {
      if (id.includes('0')) {
        el.classList.add('fade');
      } else if (id.includes('1')) {
        el.classList.add('fade-0');
      } else {
        el.classList.add('fade-btn-90-delay-1');
      }
    }
  }

  triggerVideo() {
    if (!this.platform.isBrowser()) {
      return;
    }
    const video = document.getElementById('bg-video') as HTMLVideoElement;
    video.oncanplaythrough = () => {
      video.muted = true;
      video.play();
    };
  }
}
