export enum RanchSubdivisions {
    Sagebrush = 'Sagebrush at The Ranch',
    Montrose = 'Montrose at The Ranch',
    Springs = 'Springs at The Ranch',
    Canyon = 'Canyon at The Ranch',
    Paseo = 'Paseo at The Ranch',
    Reserve = 'Reserve at The Ranch'
}

export type AreaAmenityMapConfig = {
    options: Record<string, any>,
    center: { lat: number, lng: number },
    zoom: number,
    height: string,
    width: string
};

export type AddressData = {
    address1: string,
    address2: string,
    city: string,
    zip: string,
    latitude: number,
    longitude: number,
    directions: string,
    country: string,
    stateName: string,
    stateLabel: string,
    entityId: number
};

export type CommunityAmenityCard = {
    title: string,
    tagline: string,
    icon: string,
    img: string,
    imgMobile: string
};

export type RegionPoints = {
    lat: number,
    lng: number,
    id: number
};

export type SiteplanRegionData = {
    id: string,
    color: string,
    info: string,
    points: Array<RegionPoints> | Array<Array<RegionPoints>>
};

export type SiteplanRegions = {
    [key in RanchSubdivisions]: SiteplanRegionData
};

export type SiteplanData = {
    center: { lat: number, lng: number },
    zoom: number,
    regions: SiteplanRegions,
    pdf: string,
    overlayImage: string,
    overlayPosition: {
        tr: { lat: number, lng: number }
        bl: { lat: number, lng: number }
    }
}

export type AreaAmenityCategory = {
    id: number,
    color: string,
    iconCategory: string,
    iconPin: string,
    label: string,
    name: string,
    items: Array<AreaAmenityEntry>
};

export type AreaAmenityEntry = {
    id: number,
    name: string,
    description: string,
    phone: string,
    phoneExt: string,
    status: string,
    websiteUrl: string,
    address: AddressData
};

export type CommunityConfig = {
    amenitySections: Array<CommunityAmenityCard>,
    siteplanData: SiteplanData,
    areaAmenities: Array<AreaAmenityCategory>,
};