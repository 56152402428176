import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<VideoModalComponent>,
    private platform: PlatformService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    (this.doc.defaultView.window as any)._wq = (document.defaultView.window as any)._wq || [];
    (this.doc.defaultView.window as any)._wq.push({
      id: 'y3i3brlywv', onReady: (video) => {
        video?.bind('end', () => {
          // So for some reason we can't actually get this.dialogRef.close to close the modal
          // the .bind(this) does its job, .close just does nothing.
          // instead we just simulate the click. Total hack, hate it, works.
          this.doc.getElementById('close-video').click();
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
