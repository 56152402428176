import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription, tap } from 'rxjs';
import { GalleryConfig, GalleryData } from 'src/app/data/gallery.data';
import { GalleryService } from 'src/app/services/gallery.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { HeroConfig } from 'src/app/types/hero.types';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ranch-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class RanchGalleryComponent extends ComponentBase implements OnInit, OnDestroy {
  hero: HeroConfig;
  config: GalleryConfig;
  tabIndex: number = 0;

  private subs: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private observer: ChangeDetectorRef,
    private screenService: ScreenSizeService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private title: Title,
    private galleryService: GalleryService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
    this.title.setTitle('Gallery | The Ranch');
    this.setGalleryConfig();
    this.getHeroData();
    this.navigateIfSet();
  }

  setGalleryConfig(): void {
    this.config = { ...GalleryData };

    this.subs.push(
      this.galleryService
        .getGalleryData()
        .pipe(
          tap(data => {
            this.config = {
              ...this.config,
              communityImages: GalleryData.communityImages,
              // communityImages: data.communityImages, //TODO: uncomment when possible!
              homeDesignImages: data.homeDesignImages
            };
          })
        )
        .subscribe()
    );

    // this.config.tourIframeUrls = GalleryData.tourIframeUrls.map(url =>
    //   this.sanitizer.bypassSecurityTrustResourceUrl(url as string)
    // );
  }

  setTabHandler(val: number): void {
    this.tabIndex = val;
    this.observer.detectChanges();
  }

  getHeroData(): void {
    this.hero = {
      heroImg: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Desktop+Subpage+Header/Gallery_Header_Desktop.png`,
      heroImgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Mobile+Subpage+Header/Gallery_Header_Mobile.png`,
      text: {
        heroText: 'Envision The Next<br>Chapter Of Your Life<br>Unfolding Here'
      },
      mobileText: {
        heroText: 'Envision The Next<br>Chapter Of Your Life<br>Unfolding Here'
      },
      heroClass: 'gallery-hero'
    };
  }

  navigateIfSet(): void {
    this.route.queryParams.subscribe(obs => {
      const idx = Number(obs.tab || -1);
      if (idx > 0 && idx < this.config.tabs.length) {
        this.tabIndex = idx;
        this.observer.detectChanges();
      }
    })
  }
  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
