<mat-sidenav-container hasBackdrop="true">
    <mat-sidenav #sidenav mode="over" [(opened)]="opened" position="end" (openedStart)="lockScroll('open')" (closedStart)="lockScroll('closed')">
        <ranch-menu-interest (closeModal)="sidenav.toggle()"></ranch-menu-interest>
    </mat-sidenav>
    
    <mat-sidenav-content>
        <div class="nav-item">
            <span (click)="sidenav.toggle()" class="link link-box">Join Our Interest List</span>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>