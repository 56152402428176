import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GalleryConfig } from 'src/app/data/gallery.data';

@Component({
    selector: 'ranch-tab-nav',
    templateUrl: './tab-nav.component.html',
    styleUrls: ['./tab-nav.component.scss']
})
export class RanchGalleryTabNavComponent implements OnInit {
    @Input() tabs: Array<string> = [];
    @Input() index: number = 0;
    @Output() onSetTab: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private observer: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
    }

    setTab(val: number): void {
        this.onSetTab.emit(val);
    }
}
